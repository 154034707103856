// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./img/cutting-board-3.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-results-page{
    min-height: calc(100vh - 110px);
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
}

.search-results-content{
    margin-left: 10%;
    margin-top: 1em;
}

.search-results-select-recipe{
    list-style: none;
    background-color: #FCFBF4;
    float:left;
    clear:left;
    width: auto;
    color: black;
    padding: 0.5em;
    margin-bottom: 0.25em;
    font-size: 18px;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.search-results-select-recipe:hover{
    box-shadow: inset #8080808f 0px 0px 60px -12px;
}

/* CSS FOR SMALL MOBILE */
@media only screen and (max-width: 600px) {
    .search-results-page {
        width: 100vw;
        min-width: 100vw;
        max-width: 100vw;
        min-height: calc(100vh - 142px);
        max-height: auto;
        overflow: hidden;
    }
}

/* CSS FOR TABLETS */
@media only screen and (min-device-width : 601px) and (max-device-width : 1024px) {
    .search-results-page{
        width: 100vw;
        min-width: 100vw;
        max-width: 100vw;
        min-height: calc(100vh - 171px);
        max-height: auto;
        overflow: hidden;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/css-files/searchResults.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,mDAA4C;IAC5C,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,yBAAyB;IACzB,UAAU;IACV,UAAU;IACV,WAAW;IACX,YAAY;IACZ,cAAc;IACd,qBAAqB;IACrB,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,8CAA8C;AAClD;;AAEA,yBAAyB;AACzB;IACI;QACI,YAAY;QACZ,gBAAgB;QAChB,gBAAgB;QAChB,+BAA+B;QAC/B,gBAAgB;QAChB,gBAAgB;IACpB;AACJ;;AAEA,oBAAoB;AACpB;IACI;QACI,YAAY;QACZ,gBAAgB;QAChB,gBAAgB;QAChB,+BAA+B;QAC/B,gBAAgB;QAChB,gBAAgB;IACpB;AACJ","sourcesContent":[".search-results-page{\n    min-height: calc(100vh - 110px);\n    background: url('./img/cutting-board-3.jpg');\n    background-size: cover;\n}\n\n.search-results-content{\n    margin-left: 10%;\n    margin-top: 1em;\n}\n\n.search-results-select-recipe{\n    list-style: none;\n    background-color: #FCFBF4;\n    float:left;\n    clear:left;\n    width: auto;\n    color: black;\n    padding: 0.5em;\n    margin-bottom: 0.25em;\n    font-size: 18px;\n    font-weight: 600;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n}\n\n.search-results-select-recipe:hover{\n    box-shadow: inset #8080808f 0px 0px 60px -12px;\n}\n\n/* CSS FOR SMALL MOBILE */\n@media only screen and (max-width: 600px) {\n    .search-results-page {\n        width: 100vw;\n        min-width: 100vw;\n        max-width: 100vw;\n        min-height: calc(100vh - 142px);\n        max-height: auto;\n        overflow: hidden;\n    }\n}\n\n/* CSS FOR TABLETS */\n@media only screen and (min-device-width : 601px) and (max-device-width : 1024px) {\n    .search-results-page{\n        width: 100vw;\n        min-width: 100vw;\n        max-width: 100vw;\n        min-height: calc(100vh - 171px);\n        max-height: auto;\n        overflow: hidden;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
