// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button:hover{
    cursor: pointer;
}

div {
    overflow: auto;
}

/* html,body {
    height:100%;
} */`, "",{"version":3,"sources":["webpack://./src/components/css-files/app.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,cAAc;AAClB;;AAEA;;GAEG","sourcesContent":["button:hover{\n    cursor: pointer;\n}\n\ndiv {\n    overflow: auto;\n}\n\n/* html,body {\n    height:100%;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
