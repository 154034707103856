// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CSS FOR SMALL MOBILE */
@media only screen and (max-width: 600px) {
    .master{
        min-width: 100vw;
        max-width: 100vw;
        height: 100vh;
    }
}

/* CSS FOR TABLET */
@media only screen and (min-device-width : 601px) and (max-device-width : 1024px) {
    .master{
        min-width: 100vw;
        max-width: 100vw;
        height: 100vh;
    }
}

button:active{
    box-shadow: inset #4e4e4ec9 0px 0px 60px -12px;
}
`, "",{"version":3,"sources":["webpack://./src/components/css-files/master.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;IACI;QACI,gBAAgB;QAChB,gBAAgB;QAChB,aAAa;IACjB;AACJ;;AAEA,mBAAmB;AACnB;IACI;QACI,gBAAgB;QAChB,gBAAgB;QAChB,aAAa;IACjB;AACJ;;AAEA;IACI,8CAA8C;AAClD","sourcesContent":["/* CSS FOR SMALL MOBILE */\n@media only screen and (max-width: 600px) {\n    .master{\n        min-width: 100vw;\n        max-width: 100vw;\n        height: 100vh;\n    }\n}\n\n/* CSS FOR TABLET */\n@media only screen and (min-device-width : 601px) and (max-device-width : 1024px) {\n    .master{\n        min-width: 100vw;\n        max-width: 100vw;\n        height: 100vh;\n    }\n}\n\nbutton:active{\n    box-shadow: inset #4e4e4ec9 0px 0px 60px -12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
