// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.back-btn {
    position: absolute;
    top: 130px;
    left: 20px;
    border-radius: 50%;
    text-align: center;
    width: 50px;
    height: 50px;
    line-height: 50px;
    background-color: #192733;
    -webkit-user-select: none;
            user-select: none;
    color: #FCFBF4;
    cursor: pointer;
    font-size: 30px;
}

.back-btn:hover{
    background-color: #27435c;
}

.back-btn:active{
    background-color: #2166a3;
}

/* CSS FOR SMARTPHONE */
@media only screen and (max-width: 600px) {
    .back-btn{
        top: 3vw;
        left: 3vw;
        background-color: #FCFBF4;
        color: #191919
    }
}

/* CSS FOR TABLETS */
@media only screen and (min-device-width : 601px) and (max-device-width : 1024px) {
    .back-btn{
        top: 2vw;
        left: 2vw;
        background-color: #FCFBF4;
        color: #191919
    }
}`, "",{"version":3,"sources":["webpack://./src/components/css-files/backButton.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,UAAU;IACV,UAAU;IACV,kBAAkB;IAClB,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,yBAAyB;IACzB,yBAAiB;YAAjB,iBAAiB;IACjB,cAAc;IACd,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA,uBAAuB;AACvB;IACI;QACI,QAAQ;QACR,SAAS;QACT,yBAAyB;QACzB;IACJ;AACJ;;AAEA,oBAAoB;AACpB;IACI;QACI,QAAQ;QACR,SAAS;QACT,yBAAyB;QACzB;IACJ;AACJ","sourcesContent":[".back-btn {\n    position: absolute;\n    top: 130px;\n    left: 20px;\n    border-radius: 50%;\n    text-align: center;\n    width: 50px;\n    height: 50px;\n    line-height: 50px;\n    background-color: #192733;\n    user-select: none;\n    color: #FCFBF4;\n    cursor: pointer;\n    font-size: 30px;\n}\n\n.back-btn:hover{\n    background-color: #27435c;\n}\n\n.back-btn:active{\n    background-color: #2166a3;\n}\n\n/* CSS FOR SMARTPHONE */\n@media only screen and (max-width: 600px) {\n    .back-btn{\n        top: 3vw;\n        left: 3vw;\n        background-color: #FCFBF4;\n        color: #191919\n    }\n}\n\n/* CSS FOR TABLETS */\n@media only screen and (min-device-width : 601px) and (max-device-width : 1024px) {\n    .back-btn{\n        top: 2vw;\n        left: 2vw;\n        background-color: #FCFBF4;\n        color: #191919\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
